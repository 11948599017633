import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import store from './store';
import * as db from './utils/database';
import { firestorePlugin } from 'vuefire';
import moment from 'moment';
import VueAnalytics from 'vue-analytics';
import linkifyHtml from 'linkifyjs/html';
import firebase from 'firebase';

Vue.config.productionTip = false;
Vue.use(firestorePlugin);
Vue.use(VueAnalytics, { id: 'UA-155074398-1', router });
Vue.prototype.$db = db;
Vue.filter('moment', (value: any) => {
    return moment(value).format('YYYY年MM月DD日 HH:mm');
});
Vue.prototype.$linkify = (value: any) => {
    return linkifyHtml(value, {
        defaultProtocol: 'https',
    });
};

new Vue({
    router,
    vuetify,
    store,
    render: h => h(App),
}).$mount('#app');


firebase
    .auth()
    .onAuthStateChanged(async (userInfo: firebase.User | null) => {
        if (userInfo) {
            let user = await db.getUserData(userInfo.uid);
            // 初回ログインでまだDBにデータがない場合
            if (!user) {
                user = {
                    displayName: userInfo.displayName || '',
                    photoURL: userInfo.photoURL || '',
                    uid: userInfo.uid,
                    likeCount: 0,
                    profile: ''
                };
            }
            store.dispatch('setCurrentUser', { currentUser: user });
            store.dispatch('showLoginDialog', { value: false });
        } else {
            store.dispatch('setCurrentUser', { currentUser: null });
            //router.push('/');
        }
    });