




































































































// @ is an alias to /src
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import docData from '@/plugins/doc_data.json'
import firebase from 'firebase'
import { firestore } from '@/plugins/firebase'
import store from '@/store'
import UserPost from '@/components/UserPost.vue'

const doc: any = docData
@Component({ components: { UserPost } })
export default class Forum extends Vue {
    documents = []
    entries = <any>[]
    posts = []
    postText = ''
    docId = 'all'
    showHelp = false

    get currentUser(): any {
        return store.state.currentUser
    }

    created() {
        this.entries.push({ id: 'all', title: '全て・中世文書一般' })
        Object.keys(docData).forEach(key => {
            this.entries.push(doc[key])
        })
        this.bindPosts('all')
    }

    onDocSelectionChange() {
        console.log(this.docId)
        this.bindPosts(this.docId)
    }

    bindPosts(docId: string) {
        if (docId === 'all') {
            const postsRef = firestore
                .collection('posts')
                .orderBy('timestamp', 'desc')
                .limit(50)
            this.$bind('posts', postsRef)
        } else {
            const postsRef = firestore
                .collection('posts')
                .where('docId', '==', docId)
                .orderBy('timestamp', 'desc')
                .limit(50)
            this.$bind('posts', postsRef)
        }
    }

    async createPost() {
        if (this.currentUser) {
            await firestore.collection('posts').add({
                text: this.postText,
                uid: this.currentUser.uid,
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                likers: {},
                docId: this.docId,
            })
            this.postText = ''
            this.showSnackbar()
        } else {
            this.requireLogin()
        }
    }

    showSnackbar() {
        store.dispatch('showSnackbar', { value: true, text: '投稿しました' })
    }

    requireLogin() {
        store.dispatch('showLoginDialog', { value: true })
    }
}
