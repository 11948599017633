import Vue from 'vue'
import Vuex from 'vuex'
import { firestore } from '@/plugins/firebase'
import { User } from '@/utils/database'
import UserListDialog from '../components/UserListDialog.vue'
Vue.use(Vuex)

interface State {
    currentUser: User | null
    showsLoginDialog: boolean
    showsUserProfileDialog: boolean
    showsUserProfileEditDialog: boolean
    showsUserListDialog: boolean
    userListDialogUsers: User[]
    userProfileDialogUser: any
    showsHelpDialog: boolean
    snackbar: boolean
    snackbarText: string
}

const state: State = {
    currentUser: null,
    showsLoginDialog: false,
    showsUserProfileDialog: false,
    showsUserProfileEditDialog: false,
    showsUserListDialog: false,
    showsHelpDialog: false,
    userListDialogUsers: [],
    userProfileDialogUser: {},
    snackbar: false,
    snackbarText: '',
}

export default new Vuex.Store({
    state: state,
    getters: {
        currentUser: state => {
            return state.currentUser
        },
        showsLoginDialog: state => {
            return state.showsLoginDialog
        },
    },
    mutations: {
        showLoginDialog(state, payload) {
            state.showsLoginDialog = payload.value
        },
        setCurrentUser(state, payload) {
            state.currentUser = payload.currentUser
        },
        showSnackbar(state, payload) {
            state.snackbar = payload.value
            state.snackbarText = payload.text
        },
        showUserProfileDialog(state, payload) {
            state.showsUserProfileDialog = payload.value
            state.userProfileDialogUser = payload.user
        },
        showUserProfileEditDialog(state, payload) {
            state.showsUserProfileEditDialog = payload.value
        },
        setUserProfile(state, payload) {
            if (state.currentUser) {
                state.currentUser.profile = payload.profile
            }
        },
        showUserListDialog(state, payload) {
            state.showsUserListDialog = payload.value
            state.userListDialogUsers = payload.users
        },
        showHelpDialog(state, payload) {
            state.showsHelpDialog = payload.value
        },
    },
    actions: {
        showLoginDialog(context, value) {
            context.commit('showLoginDialog', value)
        },
        setCurrentUser(context, payload) {
            context.commit('setCurrentUser', payload)
        },
        showSnackbar(context, payload: { value: boolean; text: string }) {
            context.commit('showSnackbar', payload)
        },
        showUserProfileDialog(context, payload) {
            context.commit('showUserProfileDialog', payload)
        },
        showUserProfileEditDialog(context, payload: { value: boolean }) {
            context.commit('showUserProfileEditDialog', payload)
        },
        showUserListDialog(
            context,
            payload: { value: boolean; users: User[] }
        ) {
            context.commit('showUserListDialog', payload)
        },
        showHelpDialog(context, payload: { value: boolean }) {
            context.commit('showHelpDialog', payload)
        },

        async updateUserProfile(
            context,
            payload: { profile: string; uid: string }
        ) {
            const user = context.getters.currentUser
            if (!user) return
            await firestore
                .collection('users')
                .doc(user.uid)
                .update({ profile: payload.profile })
            context.commit('setUserProfile', { profile: payload.profile })
        },
    },
})
