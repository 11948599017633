import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import Document from '@/views/Document.vue';
import Forum from '@/views/Forum.vue';
import Post from '@/views/Post.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '',
    name: 'home',
    component: Home,
  },

  {
    path: '/document/:id',
    name: 'document',
    component: Document
  },

  {
    path: '/forum',
    name: 'forum',
    component: Forum
  },

  {
    path: '/post/:id',
    name: 'post',
    component: Post
  },

];

const router = new VueRouter({
  routes,
});

export default router;
