







































// @ is an alias to /src
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import docData from '@/plugins/doc_data.json'
import firebase from 'firebase'
import { firestore } from '@/plugins/firebase'
import store from '@/store'
import UserPost from '@/components/UserPost.vue'
import UserReply from '@/components/UserReply.vue'

const doc: any = docData
@Component({ components: { UserPost, UserReply } })
export default class Post extends Vue {
    postId: string
    replyText: string = ''
    post: any = null
    replies: any[] = []

    get currentUser(): any {
        return store.state.currentUser
    }

    created() {
        this.postId = this.$route.params.id
        console.log(this.postId)
        this.bind()
    }

    async bind() {
        const postRef = firestore.collection('posts').doc(this.postId)
        await this.$bind('post', postRef)
        const replyRef = firestore
            .collection('replies')
            .where('postId', '==', this.postId)
            .orderBy('timestamp', 'asc')
        await this.$bind('replies', replyRef)
        console.log(this.replies)
    }

    async createReply() {
        if (this.currentUser) {
            await firestore.collection('replies').add({
                text: this.replyText,
                uid: this.currentUser.uid,
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                likers: {},
                docId: this.post.docId,
                postId: this.postId,
            })
            this.replyText = ''
            this.showSnackbar()
        } else {
            this.requireLogin()
        }
    }

    showSnackbar() {
        store.dispatch('showSnackbar', {
            value: true,
            text: '返信を投稿しました',
        })
    }

    requireLogin() {
        store.dispatch('showLoginDialog', { value: true })
    }
}
