









































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import docData from '@/plugins/doc_data.json'
import firebase from 'firebase'
import { firestore } from '@/plugins/firebase'
import store from '@/store'

@Component({})
export default class Home extends Vue {
    docs = Object.values(docData)
        .sort((a, b) => {
            if (a.isNew) {
                return 1
            } else if (b.isNew) {
                return -1
            } else {
                return b.year! - a.year!
            }
        })
        .reverse()

    thumbnail(filename: string) {
        return `https://os3-373-19774.vs.sakura.ne.jp/iiif/2/${filename}/full/400,/0/default.jpg`
    }
}
