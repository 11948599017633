var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('div',{attrs:{"id":"viewer"}}),_c('div',{attrs:{"id":"text"}},_vm._l((_vm.chars),function(char,index){return _c('span',{key:index,staticClass:"caption-text",class:{
                read: _vm.currentTime > char.time,
                unread: _vm.currentTime <= char.time,
                hidden: !_vm.showsText,
                small: char.small,
            },attrs:{"id":'char-' + index},domProps:{"innerHTML":_vm._s(char.char)}})}),0),_c('div',{staticClass:"center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"gray","large":"","fab":""},on:{"click":function($event){return _vm.rewind()}}},on),[_c('v-icon',[_vm._v("mdi-rewind")])],1)]}}])},[_c('span',[_vm._v("巻き戻し")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"green","dark":"","large":"","fab":""},on:{"click":function($event){return _vm.togglePlaying()}}},on),[(!_vm.isPlaying)?_c('v-icon',[_vm._v("mdi-play")]):_vm._e(),(_vm.isPlaying)?_c('v-icon',[_vm._v("mdi-pause")]):_vm._e()],1)]}}])},[(!_vm.isPlaying)?_c('span',[_vm._v("読み上げ開始")]):_vm._e(),(_vm.isPlaying)?_c('span',[_vm._v("ポーズ")]):_vm._e()]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"gray","large":"","fab":""},on:{"click":function($event){return _vm.toggleText()}}},on),[_c('v-icon',[_vm._v("mdi-furigana-vertical")])],1)]}}])},[_c('span',[_vm._v("翻字の表示")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"gray","large":"","fab":""},on:{"click":function($event){_vm.docDetailDialog = true}}},on),[_c('v-icon',[_vm._v("mdi-message-text-outline")])],1)]}}])},[_c('span',[_vm._v("資料詳細")])])],1),_c('div',{staticStyle:{"position":"absolute","right":"10px","top":"10px","opacity":"70%"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","color":"gray","fab":""},on:{"click":function($event){_vm.helpDialog = true}}},on),[_c('v-icon',[_vm._v("mdi-help")])],1)]}}])},[_c('span',[_vm._v("ヘルプ")])])],1),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.docDetailDialog),callback:function ($$v) {_vm.docDetailDialog=$$v},expression:"docDetailDialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.docDetailDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("資料詳細")])],1),_c('v-card-text',{staticClass:"doc-details pa-4"},[_c('div',{staticClass:"doc-title",domProps:{"innerHTML":_vm._s(_vm.doc.title)}}),_c('div',{staticClass:"bib"},[_vm._v(_vm._s(_vm.doc.date)+" "+_vm._s(_vm.doc.bib))]),_c('div',{staticClass:"translation pa-3"},[_c('b',[_vm._v("［解説］")]),_c('br'),_c('span',{domProps:{"innerHTML":_vm._s(_vm.doc.details)}})]),_c('div',{staticClass:"transcription pa-3"},[_c('b',[_vm._v("［釈文］")]),_c('br'),_c('span',{domProps:{"innerHTML":_vm._s(_vm.doc.transcription)}})]),_c('div',{staticClass:"translation pa-3"},[_c('b',[_vm._v("［大意］")]),_c('br'),_c('span',{domProps:{"innerHTML":_vm._s(_vm.doc.translation)}})]),(_vm.doc.yomiage)?_c('div',{staticClass:"translation pa-3"},[_c('b',[_vm._v("［読み下し］")]),_c('br'),_c('span',{domProps:{"innerHTML":_vm._s(_vm.doc.yomiage)}})]):_vm._e()])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }