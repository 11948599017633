<template>
    <div class="home">
        <div id="viewer"></div>
        <div id="text">
            <span
                v-for="(char, index) in chars"
                :key="index"
                :id="'char-' + index"
                :class="{
                    read: currentTime > char.time,
                    unread: currentTime <= char.time,
                    hidden: !showsText,
                    small: char.small,
                }"
                class="caption-text"
                v-html="char.char"
            ></span>
        </div>
        <div class="center">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn @click="rewind()" v-on="on" color="gray" large fab>
                        <v-icon>mdi-rewind</v-icon>
                    </v-btn>
                </template>
                <span>巻き戻し</span>
            </v-tooltip>

            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn @click="togglePlaying()" v-on="on" color="green" dark large fab>
                        <v-icon v-if="!isPlaying">mdi-play</v-icon>
                        <v-icon v-if="isPlaying">mdi-pause</v-icon>
                    </v-btn>
                </template>
                <span v-if="!isPlaying">読み上げ開始</span>
                <span v-if="isPlaying">ポーズ</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn @click="toggleText()" v-on="on" color="gray" large fab>
                        <v-icon>mdi-furigana-vertical</v-icon>
                    </v-btn>
                </template>
                <span>翻字の表示</span>
            </v-tooltip>

            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn @click="docDetailDialog = true" v-on="on" color="gray" large fab>
                        <v-icon>mdi-message-text-outline</v-icon>
                    </v-btn>
                </template>
                <span>資料詳細</span>
            </v-tooltip>
        </div>
        <div style="position: absolute; right: 10px; top: 10px; opacity: 70%;">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn @click="helpDialog = true" v-on="on" small color="gray" fab>
                        <v-icon>mdi-help</v-icon>
                    </v-btn>
                </template>
                <span>ヘルプ</span>
            </v-tooltip>
        </div>

        <v-dialog
            v-model="docDetailDialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="docDetailDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>資料詳細</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="doc-details pa-4">
                    <div class="doc-title" v-html="doc.title"></div>
                    <div class="bib">{{ doc.date }}&nbsp;&nbsp;{{ doc.bib }}</div>
                    <div class="translation pa-3">
                        <b>［解説］</b>
                        <br />
                        <span v-html="doc.details"></span>
                    </div>
                    <div class="transcription pa-3">
                        <b>［釈文］</b>
                        <br />
                        <span v-html="doc.transcription"></span>
                    </div>
                    <div class="translation pa-3">
                        <b>［大意］</b>
                        <br />
                        <span v-html="doc.translation"></span>
                    </div>
                    <div v-if="doc.yomiage" class="translation pa-3">
                        <b>［読み下し］</b>
                        <br />
                        <span v-html="doc.yomiage"></span>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
// @ is an alias to /src
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import docData from '@/plugins/doc_data.json'
import DocDetailDialog from '../components/DocDetailDialog.vue'

@Component
export default class Document extends Vue {
    chars = []
    overlays = []
    audio = null
    currentTime = 0
    duration = 0
    isPlaying = false
    viewer = null
    showsText = false
    docDetailDialog = false

    get helpDialog() {
        return this.$store.state.showsHelpDialog
    }

    set helpDialog(value) {
        this.$store.dispatch('showHelpDialog', { value })
    }

    togglePlaying() {
        if (this.isPlaying) {
            this.audio.pause()
            this.isPlaying = false
        } else {
            this.audio.play()
            this.isPlaying = true
        }
    }

    toggleText() {
        this.showsText = !this.showsText
    }

    rewind() {
        this.isPlaying = false
        this.audio.load()
    }

    get doc() {
        const id = this.$route.params.id
        return docData[id]
    }

    @Watch('$route.params.id', { deep: true })
    setup() {
        if (this.viewer) this.viewer.destroy()
        this.overlays = []
        const id = this.$route.params.id
        const doc = docData[id]
        //console.log(doc)
        const adjustW = doc.adjustW
        const adjustH = doc.adjustH
        this.chars = doc.chars
        this.chars.forEach((char, index) => {
            this.overlays.push({
                id: `char-${index}`,
                x: (char.x2 / doc.width) * adjustW,
                y: ((char.y1 + char.y2) / 2 / doc.height) * adjustH,
                small: char.small,
            })
        })
        this.setupViewer()
        this.setupAudio()
        this.showsText = false
    }

    setupAudio() {
        if (this.audio) this.audio.pause()
        this.isPlaying = false
        const id = this.$route.params.id
        const filename = docData[id].filename.replace('.tif', '')
        this.audio = new Audio(`/audio/${filename}.mp3`)
        //console.log(this.audio)
        this.currentTime = this.audio.currentTime
        this.duration = this.audio.duration
    }

    setupViewer() {
        const id = this.$route.params.id
        const doc = docData[id]
        this.viewer = window.OpenSeadragon({
            id: 'viewer',
            prefixUrl:
                'https://cdnjs.cloudflare.com/ajax/libs/openseadragon/2.4.1/images/',
            tileSources: `https://os3-373-19774.vs.sakura.ne.jp/iiif/2/${doc.filename}/info.json`,
            // tileSources: `https://khirin-i.rekihaku.ac.jp/iiif/ext/chusei/${doc.filename}/info.json`,
            showZoomControl: true,
            showFullPageControl: false,
            navigatorAutoFade: false,
            autoHideControls: false,
            navigationControlAnchor: 'BOTTOM_RIGHT',
            gestureSettingsMouse: {
                clickToZoom: false,
            },
            gestureSettingsTouch: {
                clickToZoom: false,
            },
            overlays: this.overlays,
        })
        this.viewer.forceRedraw()
    }
    mounted() {
        this.setup()
        setInterval(() => {
            this.currentTime = this.audio.currentTime
            if (this.audio.ended) this.isPlaying = false
        }, 1000 / 60)
    }

    beforeDestroy() {
        this.audio.pause()
    }
}
</script>
<style lang="scss">
.doc-details {
    writing-mode: vertical-rl;
    font-family: “Times New Roman”, “游明朝”, YuMincho, “ヒラギノ明朝 ProN W3”,
        “Hiragino Mincho ProN”, “メイリオ”, Meiryo, serif;
    overflow-x: scroll;
    height: calc(100vh - 64px);
    .transcription,
    .translation,
    .doc-title,
    .bib {
        position: relative;
        height: 100%;
        color: black;
        font-size: 18px;
        white-space: pre-wrap;
        line-height: 2em;
        overflow-y: initial;
    }
    .doc-title {
        padding-top: 10%;
        font-size: 22px;
        font-weight: bold;
    }
    .sup {
        vertical-align: super;
        font-size: 80%;
        margin: 4px;
    }
}
.home {
    height: 100%;
    width: 100%;
    background: black;
}
.center {
    position: fixed;
    height: 15%;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    display: inline-box;
    button {
        opacity: 0.8;
        margin: 10px;
    }
}
#viewer {
    height: 100%;
    width: 100%;
}
.caption-text {
    /* transition: font-size 1.2s ease; */
    /* transition: letter-spacing 0.5s ease; */
    writing-mode: vertical-rl;
    font-weight: bold;
    color: white;
    font-size: 22px;
    -webkit-text-stroke: 1px black;
    display: block !important;
    &.small {
        font-size: 16px;
        margin-left: 10px;
    }
}
.unread {
    color: white;
    //display: none !important;
    -webkit-text-stroke: 1px black;
}
.read {
    color: blue;
    -webkit-text-stroke: 1px white;
    cursor: pointer;
}
.hidden {
    display: none !important;
}

.transcription {
    .small {
        font-size: 14px;
        margin-left: 3px;
    }
}
</style>
