




























import Component from 'vue-class-component'
import Vue from 'vue'
import store from '../store'
import { User } from '@/utils/database'

@Component
export default class UserListDialog extends Vue {
    get users(): User[] {
        return store.state.userListDialogUsers
    }

    get showDialog() {
        return store.state.showsUserListDialog
    }

    set showDialog(value: boolean) {
        store.dispatch('showUserListDialog', { value: value })
    }

    openProfileDialog(user: User) {
        store.dispatch('showUserProfileDialog', { value: true, user })
    }
}
