











import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import store from '@/store'
import { User } from '../utils/database'

@Component
export default class LikeButton extends Vue {
    @Prop(Number) fontSize: number
    @Prop(Object) likers: { [uid: string]: boolean }

    get style() {
        return `font-size: ${this.fontSize || 20} px;`
    }

    get user(): User | null {
        return store.state.currentUser
    }

    get likedByMe() {
        if (!this.user) return false
        return this.likers[this.user.uid]
    }

    toggleLike() {
        if (this.user == null) return
        this.$emit('toggle', this.user.uid, !this.likers[this.user.uid])
        //console.log(this.likers);
    }
}
