











































import Vue from 'vue'
import Component from 'vue-class-component'
import store from '@/store'

@Component
export default class HelpDialog extends Vue {
    get showDialog() {
        return store.state.showsHelpDialog
    }

    set showDialog(value: boolean) {
        store.dispatch('showHelpDialog', { value })
    }
}
