






















import Component from 'vue-class-component'
import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import { INotification } from '@/types'
import { firestore } from '../plugins/firebase'

@Component
export default class NotificationItem extends Vue {
    @Prop(Object) notif: INotification

    user = {}

    async created() {
        this.user = await this.$db.getUserData(this.notif.data.uid)
    }

    jump() {
        firestore
            .collection('notifications')
            .doc(this.notif.id)
            .update({ state: 'read' })
        this.$router.push({
            name: 'post',
            params: {
                id: this.notif.data.postId,
            },
        })
    }
}
