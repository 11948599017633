










































import Component from 'vue-class-component'
import Vue from 'vue'
import store from '../store'
import { User } from '../utils/database'
import { firestore } from '@/plugins/firebase'
import { Watch } from 'vue-property-decorator'

@Component
export default class UserProfileEditDialog extends Vue {
    profile = ''

    get currentUser(): User | null {
        return store.state.currentUser
    }

    get showDialog() {
        return store.state.showsUserProfileEditDialog
    }

    set showDialog(value: boolean) {
        store.dispatch('showUserProfileEditDialog', { value: value })
    }

    @Watch('showDialog')
    restoreProfile() {
        if (this.currentUser) {
            this.profile = this.currentUser.profile
        }
    }

    cancel() {
        this.showDialog = false
    }

    async save() {
        if (this.currentUser) {
            this.showDialog = false
            await store.dispatch('updateUserProfile', { profile: this.profile })
            store.dispatch('showSnackbar', {
                value: true,
                text: 'プロフィールを更新しました',
            })
        }
    }
}
