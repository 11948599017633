







































import Vue from 'vue'
import firebase, { firestore } from '@/plugins/firebase'
import { Prop, Component } from 'vue-property-decorator'
import LikeButton from '@/components/LikeButton.vue'
import LikeCount from '@/components/LikeCount.vue'
import store from '@/store'
import json from '@/plugins/doc_data.json'
import { User } from '../utils/database'

@Component({
    filters: {
        cut(text: string, size: number) {
            return text.substring(0, size)
        },
    },
    components: { LikeButton, LikeCount },
})
export default class UserReply extends Vue {
    @Prop(Object) reply: any

    user: any = {}

    get currentUser(): any {
        return store.state.currentUser
    }

    get isILiked(): boolean {
        if (!this.currentUser) return false
        const uid = this.currentUser.uid
        if (!uid) return false
        return this.reply.likes[uid]
    }

    get likeCount(): number {
        if (!this.reply.likes) return 0
        let count = 0
        Object.values(this.reply.likes).forEach(value => {
            if (value) count++
        })
        return count
    }

    get likerUids() {
        return Object.entries(this.reply.likes)
            .filter(pair => pair[1])
            .map(pair => pair[0])
    }

    toggleLike(uid: string, value: boolean) {
        const key = 'likers.' + uid
        firestore
            .collection('replies')
            .doc(this.reply.id)
            .update(key, value)
    }

    showUserProfile(user: User) {
        store.dispatch('showUserProfileDialog', { user: user, value: true })
    }

    async created() {
        const uid = this.reply.uid
        const userDoc = await firestore
            .collection('users')
            .doc(uid)
            .get()
        this.user = userDoc.data() || {}
    }
}
