import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import Vue from 'vue'
import { firestorePlugin } from 'vuefire'

Vue.use(firestorePlugin)
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: 'AIzaSyApOkBLKyI-DD2EAqka7tR3XDjy7-amR78',
    authDomain: 'chusei-b8530.firebaseapp.com',
    databaseURL: 'https://chusei-b8530.firebaseio.com',
    projectId: 'chusei-b8530',
    storageBucket: 'chusei-b8530.appspot.com',
    messagingSenderId: '646173974668',
    appId: '1:646173974668:web:f67db3c0cb126d70a0dba9',
    measurementId: 'G-0DXGCPXW7N',
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)
firebase.auth()
//firebase.analytics()
export const firestore = firebase.firestore()
export default firebase;


