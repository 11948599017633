






import Component from 'vue-class-component'
import Vue from 'vue'
import { Prop } from 'vue-property-decorator'

@Component
export default class LikeCount extends Vue {
    @Prop(Number) fontSize: number
    @Prop(Object) likers: { [uid: string]: boolean }

    get style() {
        return `font-size: ${this.fontSize || 30} px;`
    }

    get likerUids() {
        const uids: string[] = []
        Object.keys(this.likers).forEach(uid => {
            if (this.likers[uid]) uids.push(uid)
        })
        return uids
    }

    get likeCount() {
        return this.likerUids.length
    }

    async display() {
        const uids = []
        for (let uid in this.likers) {
            if (this.likers[uid]) uids.push(uid)
        }
        const users = await Promise.all(
            uids.map(async uid => {
                return await this.$db.getUserData(uid)
            })
        )
        this.$store.dispatch('showUserListDialog', { value: true, users })
    }
}
