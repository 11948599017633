































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import docData from '@/plugins/doc_data.json'
import firebase from 'firebase'
import { firestore } from '@/plugins/firebase'
import store from '@/store'
import HelpDialog from '@/components/HelpDialog.vue'
import LoginDialog from '@/components/LoginDialog.vue'
import UserListDialog from '@/components/UserListDialog.vue'
import UserProfileDialog from '@/components/UserProfileDialog.vue'
import UserProfileEditDialog from '@/components/UserProfileEditDialog.vue'
import UserMenu from '@/components/UserMenu.vue'

@Component({
    components: {
        HelpDialog,
        UserMenu,
        LoginDialog,
        UserListDialog,
        UserProfileDialog,
        UserProfileEditDialog,
    },
})
export default class App extends Vue {
    docData = Object.values(docData)
        .sort((a, b) => {
            if (a.isNew) {
                return 1
            } else if (b.isNew) {
                return -1
            } else {
                return b.year! - a.year!
            }
        })
        .reverse()
    drawer = false

    get showsHelpDialog() {
        return store.state.showsHelpDialog
    }

    get showsLoginDialog() {
        return store.state.showsLoginDialog
    }

    get showsUserListDialog() {
        return store.state.showsUserListDialog
    }

    get showsUserProfileDialog() {
        return store.state.showsUserProfileDialog
    }

    get showsUserProfileEditDialog() {
        return store.state.showsUserProfileEditDialog
    }

    toggleHelpDialog(value: boolean) {
        store.dispatch('showHelpDialog', { value })
    }
}
