import firebase from '../plugins/firebase'

export interface User {
    displayName: string
    photoURL: string
    uid: string
    likeCount: number
    profile: string;
}

const database: firebase.firestore.Firestore = firebase.firestore()
const userCache: { [uid: string]: User } = {}

export async function getUserData(uid: string): Promise<User> {
    if (userCache[uid]) {
        return userCache[uid]
    } else {
        const doc = await database
            .collection('users')
            .doc(uid)
            .get()
        userCache[uid] = doc.data() as User
        return doc.data() as User
    }
}
