






























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import firebase from 'firebase'
import { firestore } from '@/plugins/firebase'
import NotificationMenu from '@/components/NotificationMenu.vue'

import store from '@/store'

@Component({
    components: { NotificationMenu },
    computed: mapState({ currentUser: (state: any) => state.currentUser }),
})
export default class UserMenu extends Vue {
    openSignInDialog() {
        store.dispatch('showLoginDialog', { value: true })
    }

    closeSignInDialog() {
        store.dispatch('showLoginDialog', { value: false })
    }

    showProfileEditDialog() {
        store.dispatch('showUserProfileEditDialog', { value: true })
    }

    async signOut() {
        await firebase.auth().signOut()
        store.dispatch('showSnackbar', {
            value: true,
            text: 'ログアウトしました',
        })
    }
}
