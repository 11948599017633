






























import Component from 'vue-class-component'
import Vue from 'vue'
import store from '../store'

@Component
export default class UserProfileDialog extends Vue {
    get user() {
        return store.state.userProfileDialogUser
    }
    get showDialog() {
        return store.state.showsUserProfileDialog
    }

    set showDialog(value: boolean) {
        store.dispatch('showUserProfileDialog', { value: value })
    }
}
