







































import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import firebase, { firestore } from '../plugins/firebase'
import { User } from '../utils/database'
import NotificationItem from './NotificationItem.vue'
import { INotification } from '../types'

const db: firebase.firestore.Firestore = firebase.firestore()

@Component({
    components: { NotificationItem },
    computed: mapState({ currentUser: (state: any) => state.currentUser }),
})
export default class NotificationMenu extends Vue {
    show = false
    notifications: INotification[] = []
    currentUser!: User

    @Watch('show')
    markChecked() {
        if (!this.show) return
        this.notifications.forEach(notif => {
            if (notif.state === 'unchecked') {
                firestore
                    .collection('notifications')
                    .doc(notif.id)
                    .update({ state: 'checked' })
            }
        })
    }

    get uncheckedCount() {
        return this.notifications.filter(notif => notif.state === 'unchecked')
            .length
    }

    created() {
        const ref = db
            .collection('notifications')
            .where('uid', '==', this.currentUser.uid)
            .orderBy('timestamp', 'desc')
            .limit(30)
        this.$bind('notifications', ref)
    }
}
