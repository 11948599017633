





























import Vue from 'vue'
import Component from 'vue-class-component'
import firebase from '@/plugins/firebase'
import store from '@/store'

@Component({
    props: { value: Boolean },
    computed: {
        open: {
            get() {
                return this.$store.getters.showsLoginDialog
            },
            set(value) {
                this.$store.dispatch('showLoginDialog', value)
            },
        },
    },
})
export default class LoginDialog extends Vue {
    async signInWithTwitter() {
        this.$emit('close')
        const provider = new firebase.auth.TwitterAuthProvider()
        const credential = await firebase.auth().signInWithPopup(provider)
        store.dispatch('showSnackbar', {
            value: true,
            text: 'ログインしました',
        })
    }

    async signInWithGoogle() {
        this.$emit('close')
        const provider = new firebase.auth.GoogleAuthProvider()
        const credential = await firebase.auth().signInWithPopup(provider)
        store.dispatch('showSnackbar', {
            value: true,
            text: 'ログインしました',
        })
    }
}
